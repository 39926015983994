import { graphql, Link } from "gatsby"
import React from "react"

import { Layout, PageTitle } from "../components/layout"
import { Screen } from "../responsive"

interface Props {
  data: PageData
}

interface PageData {
  allPrismicProduct: {
    nodes: Array<{
      uid: string
      data: {
        title: { text: string }
        main_thumb_image: {
          alt?: string
          url?: string
        }
      }
    }>
  }
}

export default (props: Props) => {
  const imgHeight = 285
  const imgWidth = 380

  return (
    <Layout>
      <PageTitle>Visi projektai</PageTitle>
      <div css={{}}>
        <div
          css={{
            display: "grid",
            gridGap: "1.5rem",
            justifyContent: "center",
            margin: "2rem auto",
            [Screen.XS]: {
              gridTemplateColumns: `repeat(auto-fill, ${imgWidth}px)`,
              maxWidth: "64rem",
            },
          }}
        >
          {props.data.allPrismicProduct.nodes.map(p => (
            <Link to={`/projektai/${p.uid}`}>
              <div css={{ position: "relative" }}>
                <img
                  key={p.uid}
                  alt={p.data.main_thumb_image.alt}
                  src={p.data.main_thumb_image.url}
                  css={{
                    height: imgHeight * 0.8,
                    width: imgWidth * 0.8,
                    objectFit: "cover",
                    [Screen.XS]: {
                      height: imgHeight,
                      width: imgWidth,
                    },
                    background: "#ececec",
                  }}
                />
                <p css={{ margin: "0.75rem 0 1.5rem", fontSize: "0.85rem" }}>
                  {p.data.title.text}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allPrismicProduct {
      nodes {
        uid
        data {
          title {
            text
          }
          main_thumb_image {
            alt
            url
          }
        }
      }
    }
  }
`
